
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.wrapper {
    background-image: url("/assets/images/background.jpg");
    background-color: #f2f6ee;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    @include lg {
        background-position: top left;
    }
}
.step-block {
    &:first-child {
        .form-field {
            & > label {
                margin-bottom: 10px;
                font-size: 16px;

                font-weight: 700;
                line-height: 1.4;
                text-align: center;
                @include lg {
                    font-size: 20px;
                }
            }
        }
    }
}
.main-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: start;
    min-height: calc(100vh - 60px);
    @include lg {
        gap: 100px;
        flex-direction: row;
        align-items: start;
        justify-content: center;
    }

    .form {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
        background-color: #fff;
        border-radius: 10px;
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 550px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid #a2c8a8;
        margin: 5vh 0;
        @include lg {
            padding: 40px;

            @media screen and (max-width: 1200px) {
                max-width: 500px;
            }
        }

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 24px !important;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $bestrounduplawyers-green !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;

                input {
                    &:checked + label {
                        background-color: $bestrounduplawyers-green;
                        color: #fff;
                    }
                }
                label {
                    border-radius: 3px;
                    background-color: $bestrounduplawyers-green-light;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    font-weight: 700;
                    @media screen and (max-width: 767px) {
                        padding: 10px 5px;
                    }
                    &:hover {
                        background-color: $bestrounduplawyers-green;
                        color: #fff;
                    }
                    span {
                        font-size: 16px !important;
                        @media screen and (max-width: 767px) {
                            font-size: 15px !important;
                        }
                    }
                    & > div {
                        margin-right: 15px;

                        & + span {
                            margin-top: 0;
                            margin-right: inherit;
                            width: auto;
                        }
                    }
                    & > span {
                        margin-right: auto;
                        width: 100%;
                    }

                    @include md {
                        min-height: 58px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
}
.progress-background {
    & > div:last-child {
        background-color: white;
    }
}

.progress-background {
    & > div:first-child span {
        color: #fff;
    }
    & > div:last-child {
        background-color: #f3f3f3;
    }
}
